<template>
  <div id="app-layout">           
    <div class="home__title">
              <h2 class="home__title__bg">NOTRE OFFICE</h2>
            </div>         
            <div class="pagecontent">
              <section id="presentation">
                <!--<h2 class="office__title">Les locaux</h2>-->
                <div class="office">
                    <div class="slideshowcontainer">
                      <div class="rea__slide">
          <button class="controls" id="previous"><i class="fas fa-chevron-left"></i></button>
          <div id="container">
            <ul id="all_slides">
            <li class="slide activ"></li>
            <li class="slide"></li>
            <li class="slide"></li>
            <li class="slide"></li>
            <li class="slide"></li>
            <li class="slide"></li>
            <li class="slide"></li>
            <li class="slide"></li>
            <li class="slide"></li>
            </ul>
          </div>
          <button class="controls" id="next"><i class="fas fa-chevron-right"></i></button>
          </div>
      </div>
                      <!--<v-carousel
                        cycle
                        interval="3000"
                        hide-delimiters
                        :show-arrows="false">
                        <v-carousel-item reverse-transition="fade-transition"
                        transition="fade-transition">
                            <div class="display-3">
                                <img class="office__img" src="../assets/office1.webp" alt="Salle d'attente de l'office avec chaises et canapé">
                            </div>
                        </v-carousel-item>
                        <v-carousel-item reverse-transition="fade-transition"
                        transition="fade-transition">
                            <div class="display-3">
                                <img class="office__img" src="../assets/office2.webp" alt="Entrée intérieure de l'office">
                            </div>
                        </v-carousel-item>
                        <v-carousel-item reverse-transition="fade-transition"
                        transition="fade-transition">
                            <div class="display-3">
                                <img class="office__img" src="../assets/office3.webp" alt="Symbole des notaires">
                            </div>
                        </v-carousel-item>
                        <v-carousel-item reverse-transition="fade-transition"
                        transition="fade-transition">
                            <div class="display-3">
                                <img class="office__img" src="../assets/office4.webp" alt="Fresque landaise dans le bureau de l'office">
                            </div>
                        </v-carousel-item>
                        <v-carousel-item reverse-transition="fade-transition"
                        transition="fade-transition">
                            <div class="display-3">
                                <img class="office__img" src="../assets/office5.webp" alt="Extérieur de l'office">
                            </div>
                        </v-carousel-item>
                        <v-carousel-item reverse-transition="fade-transition"
                        transition="fade-transition">
                            <div class="display-3">
                                <img class="office__img" src="../assets/office6.webp" alt="Entrée extérieure de l'office">
                            </div>
                        </v-carousel-item>
                       <v-carousel-item reverse-transition="fade-transition"
                        transition="fade-transition">
                            <div class="display-3">
                                <img class="office__img" src="../assets/office8.webp" alt="Entrée intérieure de l'office avec fresque">
                            </div>
                        </v-carousel-item>
                         <v-carousel-item reverse-transition="fade-transition"
                        transition="fade-transition">
                            <div class="display-3">
                                <img class="office__img" src="../assets/office7.webp" alt="Accueil de l'office">
                            </div>
                        </v-carousel-item>
                        <v-carousel-item reverse-transition="fade-transition"
                        transition="fade-transition">
                            <div class="display-3">
                                <img class="office__img" src="../assets/officehome.webp" alt="Extérieur de l'office vu du parking">
                            </div>
                        </v-carousel-item>
                      </v-carousel>
                    </div>-->
                    <p class="office__text">L'office notarial de M<sup>e</sup> Angélique Montagner à Seignosse vous souhaite la bienvenue.<br><br>Situé entre terre et océan dans le bourg de Seignosse, notre office vous accueille depuis 2018 pour toutes vos démarches notariales. M<sup>e</sup> Angélique Montagner et ses collaborateurs ont a coeur de vous accompagner dans toutes les grandes étapes de votre vie : immobilier, famille, entreprise...<br><br>Votre notaire à Seignosse se tient à votre disposition pour authentifier tous vos contrats et pour tous conseils  juridiques.</p>
                </div>
              </section>
              <!--<section id="equipe">
                <h2 class="office__title">L'équipe</h2>
                <div class="equipe">
                  <div class="equipe__member">
                    <img class="equipe__img" src="../assets/notaire.svg">
                    <div class="equipe__text">
                      <h3 class="equipe__name">M<sup>e</sup> Angélique MONTAGNER</h3>
                      <p class="equipe__title">Notaire</p>
                    </div>
                  </div>
                  <div class="equipe__member">
                    <img class="equipe__img" src="../assets/notaire.svg">
                    <div class="equipe__text">
                      <h3 class="equipe__name"></h3>
                      <p class="equipe__title">Notaire assistante</p>
                    </div>
                  </div>
                  <div class="equipe__member">
                    <img class="equipe__img" src="../assets/notaire.svg">
                    <div class="equipe__text">
                      <h3 class="equipe__name"></h3>
                      <p class="equipe__title">Assistante notariale</p>
                    </div>
                  </div>
                </div>
              </section>-->
      </div>
  </div>
</template>

<script>
  export default {
    metaInfo: {
    // Children can override the title.
    title: 'Notre Office - Office Notarial de Me Montagner',
    link: [
    {rel: 'canonical', href: 'https://angelique-montagner.notaires.fr/Office'}
   ]
  },
    name: 'Office',
    mounted() {
      /********************* slideshow ******************/
        "use strict";


          let slides = document.querySelectorAll('#all_slides .slide');
          let currentSlide = 0;

          const nextSlide = () => {
              goToSlide(currentSlide + 1);
          }

          const previousSlide = () => {
              goToSlide(currentSlide - 1);
          }

          let slideInterval = setInterval(nextSlide, 3000); // Ici Vitesse

          const goToSlide = (s) => {

              slides[currentSlide].className = 'slide';
            
              currentSlide = (s + slides.length) % slides.length;
              slides[currentSlide].className = 'slide activ';
          }

          const pauseSlideshow = () => {
              clearInterval(slideInterval);
          }
          const playSlideshow = () => {
              slideInterval = setInterval(nextSlide, 3000); // Ici vitesse
          }

          let next_btn = document.querySelector('#next');
          let previous_btn = document.querySelector('#previous');

          next_btn.onclick = () => {
            pauseSlideshow();
              nextSlide();
            playSlideshow();
          };
          previous_btn.onclick = () => {
              pauseSlideshow();
              previousSlide();
              playSlideshow();
          };
    }
}
</script>

<style lang="scss">
$tertiary-color: #e5ac5f;

.pagecontent {
    width: 75%;
    margin: auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: rgba(250,250,250,0.95);
    border-radius: 3px;
    @media screen and (max-width: 979px){
        width: 90%;
    }
}

.office {
    @media screen and (max-width: 875px){
        flex-direction: column;
         }
    &__img {
        width: 100%;
        height: 600px;
        box-sizing: border-box;
        object-fit: cover;
        object-position: center 100%;
        @media screen and (max-width: 875px){
            height: 300px;
             }
    }
    &__text{
        text-align: justify;
        margin: auto;
        width: 80%;
        margin-top: 60px;
        margin-bottom: 60px;
        @media screen and (max-width: 875px){
            width: 95%;
             }
    }
    &__title {
      margin: auto;
      text-align: center;
      margin-bottom: 30px;
      background-color: $tertiary-color;
      border-radius: 3px;
      display: block;
      width: fit-content;
      padding: 5px 10px;
    }
}

.slideshowcontainer {
  margin-bottom: 20px;
}

.v-carousel__item {
  height: 600px!important;
  @media screen and (max-width: 875px){
            height: 300px!important;
             }
}

.v-carousel {
  height: 600px!important;
   @media screen and (max-width: 875px){
            height: 300px!important;
             }
}

.display-3 {
  width: 100%;
  border-radius: 3px;
}

#presentation {
    margin-bottom: 20px;
}

.equipe {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: auto;
  @media screen and (max-width: 669px){
            flex-direction: column;
             }
  &__member {
    width: 30%;
    text-align: center;
    @media screen and (max-width: 669px){
            width: 100%;
            margin-bottom: 30px;
             }
  }
  &__img {
    width: 70%;
    margin: auto;
  }
}

 /************** Slideshow */

  #container {
    width: 60%;
    aspect-ratio: 4 / 3;
    margin: auto;
    overflow: hidden;
    @media screen and (max-width: 769px){
            width: 100%;
            }
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    width: 100%;
}
  }

.rea__slide {
  display: flex;
  align-items: center;
}

#all_slides{
  position: relative;
  padding: 0px;
  margin: auto;
  width: 100%;
  height: 100%;
  list-style-type: none;
}

.slide{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  -webkit-transition: opacity 1s;
  -moz-transition: opacity 1s;
  -o-transition: opacity 1s;
  transition: opacity 1s;
}

.activ{
  opacity: 1;
  z-index: 2;
  background-color: transparent!important;
}

.slide{
  font-size: 40px;
  padding: 40px;
  box-sizing: border-box;
  color: #fff;
  background-size: cover;
  border-radius: 5px;
  z-index: 1;
}

.slide:nth-of-type(1){
  background-image: url('../assets/office1.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  }

.slide:nth-of-type(2){
  background-image: url('../assets/office2.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.slide:nth-of-type(3){
  background-image: url('../assets/office3.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.slide:nth-of-type(4){
  background-image: url('../assets/office4.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.slide:nth-of-type(5){
  background-image: url('../assets/office5.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.slide:nth-of-type(6){
  background-image: url('../assets/office6.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.slide:nth-of-type(7){
  background-image: url('../assets/office7.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.slide:nth-of-type(8){
  background-image: url('../assets/office8.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.slide:nth-of-type(9){
  background-image: url('../assets/officehome.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.controls{
  display: none;
}

.controls{
  display: inline-block;
  position: relative;
  top: 1rem;
  right: .5rem;
  border: none;
  outline: none;
  font-size: 20px;
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  margin-left: .5rem;
  background-color: transparent;
  @media screen and (max-width: 769px){
    top: 0;            
    right: 0;
    margin-left: 0;
    width: 1.5rem;
            }
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    top: 0;
    right: 0;
    margin-left: 0;
    width: 1.5rem;
}
}


.container{
  position: relative;
}
</style>